import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/datacentersales/search', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/datacentersales/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/datacentersales/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/datacentersales/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createDatacenterSalesStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/createDatacenterSalesStock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    batchCreateDatacenterSalesStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/batchCreateDatacenterSalesStock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createSalesOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/createSalesOrder', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    batchCreateSalesOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/batchCreateSalesOrder', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    test(ctx, params) {
      return new Promise((resolve, reject) => {
        window.location.href = 'http://dev.localhost:8080/api/datacentersales/test';
        // axios
        //     .get('/api/datacentersales/test', {params: params})
        //     .then(response => resolve(response))
        //     .catch(error => reject(error))
      })
    },
    export(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/export', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    delete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/delete', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    batchDelete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/batchDelete', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportOSO(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/exportOSO', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getStockNo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/datacentersales/getStockNo', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
