<template>
  <b-card
    class="datacentersales-edit-wrapper"
  >
    <!-- form -->
    <b-form id="datacentersalesForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="平台ID   store_brand"
            label-for="store_company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_company_id"
              size="sm"
              v-model="datacentersales.store_company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售场次"
            label-for="sales_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_name"
              size="sm"
              v-model="datacentersales.sales_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺Id"
            label-for="store_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_id"
              size="sm"
              v-model="datacentersales.store_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺名称"
            label-for="store_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_name"
              size="sm"
              v-model="datacentersales.store_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品名称"
            label-for="product_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_name"
              size="sm"
              v-model="datacentersales.product_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="datacentersales.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="product_code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_code"
              size="sm"
              v-model="datacentersales.product_code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供货价"
            label-for="price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="price"
              size="sm"
              v-model="datacentersales.price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品名称"
            label-for="out_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_name"
              size="sm"
              v-model="datacentersales.out_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品ID"
            label-for="out_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_id"
              size="sm"
              v-model="datacentersales.out_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库名称"
            label-for="wh_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="wh_name"
              size="sm"
              v-model="datacentersales.wh_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="卖场名称"
            label-for="area_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="area_name"
              size="sm"
              v-model="datacentersales.area_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单位数(每份几个)"
            label-for="sell_unit_cnt"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sell_unit_cnt"
              size="sm"
              v-model="datacentersales.sell_unit_cnt"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单位名称(每份还是每箱)"
            label-for="sell_unit_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sell_unit_name"
              size="sm"
              v-model="datacentersales.sell_unit_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销量(按销量份数)"
            label-for="sell_total"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sell_total"
              size="sm"
              v-model="datacentersales.sell_total"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="总销量(按销量单位)   sell_total * sell_unit_cnt"
            label-for="sell_unit_total"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sell_unit_total"
              size="sm"
              v-model="datacentersales.sell_unit_total"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="提货日期"
            label-for="expct_pick_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expct_pick_time"
              size="sm"
              v-model="datacentersales.expct_pick_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="datacentersales.create_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import datacentersalesStore from './datacentersalesStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      datacentersales: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('datacentersales')) store.registerModule('datacentersales', datacentersalesStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('datacentersales')) store.unregisterModule('datacentersales')
    })

    const edit = function() {
      store.dispatch('datacentersales/edit', {id: this.id}).then(res => {
        this.datacentersales = res.data.data
      })
    }

    const view = function() {
      store.dispatch('datacentersales/view', {id: this.id}).then(res => {
        this.datacentersales = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('datacentersales/save', this.datacentersales).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>